export const WORDS = [
  'ryanp',
  'kenji',
  'conor',
  'beast',
  'vince',
  'grace',
  'supah',
  'ninja',
  'titan',
  'logan',
  'japan',
  'white',
  'tiger',
  'comic',
  'waldo',
  'tokyo',
  'mckay',
  'jones',
  'peter',
  'riker',
  'nomad',
  'quake',
  'flint',
  'jason',
  'drake',
  'honey',
  'lemon',
  'karma',
  'dinos',
  'akira',
  'asian',
]
